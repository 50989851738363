import React, { lazy } from "react";

import { Icon } from "@chakra-ui/react";
import {
    MdLocalTaxi,
    MdFingerprint,
    MdGroup,
    MdRotateLeft,
    MdHome,
    MdSettings,
    MdAccessTime,
    MdOutlineAirlineSeatReclineExtra,
    MdDeviceHub
} from "react-icons/md";

// Admin Imports
const MainDashboard = lazy(() => import("views/admin/dashboard"));
const ManageVehicles = lazy(() => import("views/admin/vehicles"));
const Users = lazy(() => import("views/admin/users"));
const Departments = lazy(() => import("views/admin/departments"));
const Drivers = lazy(() => import("views/admin/drivers"));
const Versions = lazy(() => import("views/admin/versions"));

// Auth Imports
const SignInCentered = lazy(() => import("views/auth/signIn"));

// Account
const Profile = lazy(() => import("views/account/profile"));
const Activity = lazy(() => import("views/account/activity"));
const AuditHistory = lazy(() => import("views/account/audit"));

const ROLES = {
    'ADMINISTRATOR': 1,
    "USER": 2
}

const routes = [
    {
        name: "Main Dashboard",
        layout: "/admin",
        path: "/dashboard",
        icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR, ROLES.USER],
        component: <MainDashboard />,
    },
    {
        name: "Vehicles",
        layout: "/admin",
        path: "/vehicles",
        icon: (
            <Icon
                as={MdLocalTaxi}
                width='20px'
                height='20px'
                color='inherit'
            />
        ),
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR],
        component: <ManageVehicles />,
        secondary: true,
    },
    {
        name: "Drivers",
        layout: "/admin",
        icon: <Icon as={MdOutlineAirlineSeatReclineExtra} width='20px' height='20px' color='inherit' />,
        path: "/drivers",
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR],
        component: <Drivers />,
    },
    {
        name: "Departments",
        layout: "/admin",
        icon: <Icon as={MdDeviceHub} width='20px' height='20px' color='inherit' />,
        path: "/departments",
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR],
        component: <Departments />,
    },
    {
        name: "Users",
        layout: "/admin",
        icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
        path: "/users",
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR],
        component: <Users />,
    },
    {
        name: "Document Versions",
        layout: "/admin",
        path: "/versions",
        icon: (
            <Icon
                as={MdAccessTime}
                width='20px'
                height='20px'
                color='inherit'
            />
        ),
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR],
        component: <Versions />,
        secondary: true,
    },
    {
        name: "Settings",
        layout: "/account",
        path: "/settings",
        icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR, ROLES.USER],
        component: <Profile />,
    },
    {
        name: "Login History",
        layout: "/account",
        path: "/audit",
        icon: <Icon as={MdFingerprint} width='20px' height='20px' color='inherit' />,
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR, ROLES.USER],
        component: <AuditHistory />,
    },
    {
        name: "Activity History",
        layout: "/account",
        path: "/activity",
        icon: <Icon as={MdRotateLeft} width='20px' height='20px' color='inherit' />,
        persist: true,
        allowedRoles: [ROLES.ADMINISTRATOR, ROLES.USER],
        component: <Activity />,
    },
    {
        name: "Sign In",
        layout: "/auth",
        path: "/",
        root: true,
        component: <SignInCentered />,
    },
    {
        name: "Reset Password",
        layout: "/auth",
        path: "/reset",
        component: <SignInCentered />,
    },
    {
        name: "Error 500",
        layout: "/auth",
        path: "/500",
        component: <SignInCentered />,
    },
    {
        name: "Error 404",
        layout: "/auth",
        path: "*",
        root: true,
        component: <SignInCentered />,
    },
];

export default routes;