import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import App from "./App";
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './contexts/AuthProvider';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<ThemeEditorProvider>
					<AuthProvider>
						<App />
						<ToastContainer />
					</AuthProvider>
				</ThemeEditorProvider>
			</React.StrictMode>
		</ChakraProvider>
	</QueryClientProvider>,
	document.getElementById('root')
);

serviceWorker.unregister();