import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from './components/spinner/Spinner';
import RequireAuth from './auth/RequireAuth';
import PersistLogin from './auth/PersistLogin';
import routes from "_paths";

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.hasOwnProperty('root')) {
      if (prop.root === true) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
    }
    if (prop.hasOwnProperty('persist')) {
      if (prop.persist === true) {
        return (
          <Route element={<PersistLogin />}>
            <Route
              element={
                <RequireAuth allowedRoles={prop.allowedRoles} />
              }
            >
              <Route
                path={prop.layout + prop.path}
                element={prop.component}
                key={key}
              />
            </Route>
          </Route>
        );
      }
    }
    return (
      <Route
        path={prop.layout + prop.path}
        element={prop.component}
        key={key}
      />
    );
  });
};

const Routers = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;